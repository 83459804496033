import React from "react"
import Container from "../components/container/container";
import SEO from "../components/seo";
import YM from "../components/analytics/ym";
import Shpaklevka from "../components/articles/shpaklevka";

const ShpaklevkaPage = () => (
    <Container>
        <SEO
            title="complexstroy.by | Как правильно шпаклевать стены"
            description="Как правильно шпаклевать стены"/>
        <YM />
       <Shpaklevka />
    </Container>
);

export default ShpaklevkaPage
