import React from "react"
import styles from "./article-pages.module.css"
import {graphql, Link, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import LinkButton from "../buttons/link-button/link-button";
import Background from "../../images/shpat_bg.png";

const Shpaklevka = () => {

    const data = useStaticQuery(graphql`
    query {
        articleImg: file(relativePath: { eq: "shpat_img.png" }) {
          childImageSharp {
            fluid(maxWidth: 970) {
              ...GatsbyImageSharpFluid
            }
          }
        }
    }
  `);

    return (
        <ModalRoutingContext.Consumer>
            {({ modal, closeTo }) => (
                <div className={modal ? styles.articleModal : styles.articlePage}>
                    {modal ? (
                        <Link className={"close-modal"} to={"/#articles"}>&times;</Link>
                    ) : "" }
                    <div className={styles.articleBackground} style={{
                        backgroundImage: `url(${Background})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }}>
                        <div className={styles.imageBackground}>
                            <p className={styles.backgroundText}>
                                Как правильно шпаклевать стены
                            </p>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <h2>Шпаклевка</h2>
                        <p className={styles.articleText}>
                            Качественная подготовка стен к различным отделочным работам заключается в получении ровной, гладкой поверхности.
                            Для этого необходимо грамотно выполнить грунтование и шпаклевание.
                            Шпаклевание – нетрудное занятие. Здесь главное соблюдать порядок действий и уметь правильно обращаться со строительным инструментом.
                            Как правильно шпатлевать стены самостоятельно не прибегая к помощи специалистов мы рассмотрим в данной статье.
                        </p>
                        <div className={styles.articleImage}>
                            <Img fluid={data.articleImg.childImageSharp.fluid} />
                        </div>
                        <h2>Какой инструмент необходим для шпаклевания</h2>
                        <p className={styles.articleText}>
                            1. Дрель, оснащенная специальной насадкой - миксером. Как правило, шпаклевка продается в виде сухой смеси. В такую смесь в определенной пропорции добавляют воду. Только специальным миксером вы сможете идеально размешать шпаклевочную смесь. На выходе должна получиться однородная шпаклевка без комков.
                            <br />
                            <br />
                            2. Набор шпателей. Обязательно подобрать шпателя разного размера. В работе вам придется пользоваться и большими шпателями (40-50 см), и совсем маленькими. Ведь в проблемных труднодоступных местах не удастся качественно шпаклевать стену большим шпателем. Как раз для этого и применяются маленькие шпателя.
                            <br />
                            <br />
                            3. Валики и кисти для грунтовки стен. Этап грунтования нежелательно пропускать. После грунтовки стена покрывается тончайшей пленкой. Это не только дополнительно повышает ее прочность, но и обеспечивает отличное сцепление обработанной стены с любыми облицовочными материалами (адгезия).
                            <br />
                            <br />
                            4. Правило. Понадобится в случае неровных стен когда приходится шпаклевку наносить толстым слоем. Для разравнивания толстых слоев шпаклевки удобно пользоваться длинным металлическим правилом.
                            <br />
                            <br />
                            5. Спиртовой или лазерный уровень. Часто поверхность стены очень неровная. Прежде чем начинать стартовое шпаклевание, устанавливают проверочные маяки, применяя для проверки спиртовой или лазерный уровень.
                            <br />
                            <br />
                            6. Наждачная бумага. Если желаете получить красивую поверхность, на которую приятно смотреть, применяйте для затирания мелкие шкурки. Например, затирка 240-й шкуркой позволит вам получить идеальный результат. Мельчайшую шкурку применяйте только при выполнении финишной шпаклевки. А на этапе стартовой начальной шпаклевки правильно затирать стену грубой крупной шкуркой.
                            <br />
                            <br />
                            7. Ручной шкуротер. Затирка шкуркой станет намного удобнее, если пользоваться ручным шкуротером. Это инструмент, на ручке которого установлены специальные зажимы для закрепления шкурки.
                            <br />
                            <br />
                            8. Ну и, конечно, не обойтись без емкости, в которой размешивается шпаклевка.
                        </p>
                        <div style={{textAlign: 'center', marginTop: '40px'}}>
                            <LinkButton text={'Вернуться на сайт'} path={modal ? "/#articles" : ""}/>
                        </div>
                    </div>
                </div>
            )}
        </ModalRoutingContext.Consumer>
    );
};

export default Shpaklevka
